<template>
  <div>
    <h2 class="message">
      <i class="material-icons">error_outline</i>
      <span>{{ $t('errors.internal') }}</span>
    </h2>
  </div>
</template>

<script>
export default {name: 'internal-error'}
</script>

