<template>
  <div>
    <h2 class="message">
      <i class="material-icons">error</i>
      <span>{{ $t('errors.forbidden') }}</span>
    </h2>
  </div>
</template>

<script>
export default {name: 'forbidden'}
</script>

